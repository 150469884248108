import { useEffect, useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppInterval, AppToken } from "../App";
import {
  MRForecastPendingData,
  TaskStatusRequest,
  TaskStatusResponseData,
} from "../types/api";
import { Button, DateTime, Line, Loader, Text, notify } from "../components";
import CheckBox2 from "../components/common/Checkbox";
import MRForecastBox from "../components/common/MRForecastBox";
import TableBox from "../components/common/TableBox";
import { DEFAULT_LIMIT, TS_MRFORECAST } from "../constants";
import { COLORS } from "../styles/colors";
import { FONT_WEIGHT } from "../styles/fonts";
import { getMRForecastStatus } from "../utils/apiHelper";
import DisplayModal from "./common/DisplayModel";

interface ISearchForm {
  rowId?: number;
  server?: string;
  description?: string;
  status?: string;
  highLight?: boolean;
}

const MRforecastPage = () => {
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState<TaskStatusResponseData>(
    [],
  );
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffSet] = useState(0);
  const [pendingOffset, setPendingOffSet] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refreshTime, setRefreshTime] = useState("");
  const [isInit, setIsInit] = useState(true);
  const [highLight, setHighLight] = useState(true);
  const [alertColor, setAlertColor] = useState(true);
  const [pendingTotal, setPendingTotal] = useState(0);
  const [pendingResults, setPendingResults] = useState<MRForecastPendingData>(
    [],
  );
  const { handleSubmit, setValue } = useForm<ISearchForm>({});
  const { authorizedToken } = JSON.parse(useContext(AppToken));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPendingModalOpen, setIsPendingModalOpen] = useState(false);
  const [statusQuery, setStatusQuery] = useState("");
  const [pendingQuery, setPendingQuery] = useState("");
  const { frequencyInterval, setFrequencyInterval } = useContext(AppInterval);
  const refreshTimer =
    Number(frequencyInterval.frequencyInterval) > 0
      ? Number(frequencyInterval.frequencyInterval) * 1000
      : 0;
  useEffect(() => {
    const getData = async () => {
      setIsShowSpinner(true);
      setTotalRecords(0);
      setRefreshTime("");
      setFrequencyInterval({
        frequencyInterval: frequencyInterval.frequencyInterval,
        tabName: TS_MRFORECAST,
      });
      const { errorMessage, response } = await getMRForecastStatus(
        {
          taskType: TS_MRFORECAST,
          limit: limit.toString(),
          offset: "0",
          pendingOffset: "0",
          highLight: `${highLight}`,
        },
        authorizedToken,
      );

      if (errorMessage) {
        setIsShowSpinner(false);
        notify({
          message: errorMessage ?? "Error while loading page",
          type: "error",
        });
        return;
      }

      if (response) {
        setSearchResults(response.data.mrStatusData.tasksList);
        setTotalRecords(response.data.mrStatusData.totalCount);
        setRefreshTime(response.data.mrStatusData.refreshTime);
        setPendingTotal(response.data.mrPendingData.totalCount);
        setPendingResults(response.data.mrPendingData.mrForecastPendingData);
        setStatusQuery(response.data.displayQuery.statusQuery);
        setPendingQuery(response.data.displayQuery.pendingQuery);
        setIsShowSpinner(false);
      }
    };
    if (isInit) {
      getData();
    }
  }, [limit, isInit, highLight, setValue]);

  useEffect(() => {
    fetchRefreshResults({
      limit,
      offset,
    });
  }, [highLight]);

  useEffect(() => {
    if (refreshTimer > 0 && TS_MRFORECAST === frequencyInterval.tabName) {
      console.log(`refreshTimer: ${refreshTimer}`);
      const comInterval = setInterval(fetchRefreshResults, refreshTimer);
      return () => clearInterval(comInterval);
    }
  }, [frequencyInterval]);

  const fetchRefreshResults = async (request: TaskStatusRequest) => {
    setIsShowSpinner(true);
    setIsInit(false);
    if (request && request.limit) {
      setLimit(limit);
    }
    if (request && request.offset) {
      setOffSet(request.offset);
    }
    if (request && request.pendingOffset) {
      setPendingOffSet(request.pendingOffset);
    }
    if (request && request.highLight) {
      setHighLight(request.highLight);
    }
    const modifiedRequest = {
      taskType: request ? request.taskType : TS_MRFORECAST || TS_MRFORECAST,
      limit: request
        ? request.limit?.toString()
        : limit.toString() || limit.toString(),
      offset: request
        ? request.offset?.toString()
        : offset.toString() || offset.toString(),
      pendingOffset: request
        ? request.pendingOffset?.toString()
        : pendingOffset.toString() || pendingOffset.toString(),
      highLight: request
        ? request.highLight?.toString()
        : `${highLight}` || `${highLight}`,
    };

    const { errorMessage, response } = await getMRForecastStatus(
      modifiedRequest,
      authorizedToken,
    );

    if (errorMessage) {
      setIsShowSpinner(false);
      notify({
        message: errorMessage ?? "Error while loading page",
        type: "error",
      });
      return;
    }

    if (response) {
      setSearchResults(response.data.mrStatusData.tasksList);
      setTotalRecords(response.data.mrStatusData.totalCount);
      setRefreshTime(response.data.mrStatusData.refreshTime);
      setPendingTotal(response.data.mrPendingData.totalCount);
      setPendingResults(response.data.mrPendingData.mrForecastPendingData);
      setIsShowSpinner(false);
    }
  };

  const onSubmit: SubmitHandler<ISearchForm> = () => {
    fetchRefreshResults({
      limit,
      offset,
      pendingOffset,
      highLight,
    });
  };

  const handleChecked = () => {
    setHighLight(highLight ? false : true);
  };

  const showColor = () => {
    setAlertColor(!alertColor);
  };

  const openPopup = () => setIsModalOpen(true);
  const openPendingPopup = () => setIsPendingModalOpen(true);

  return (
    <>
      {isShowSpinner ? (
        <Loader
          style={{
            alignItems: "center",
            flex: 1,
          }}
        />
      ) : null}
      <div
        style={{
          width: "auto",
          height: "auto",
          opacity: isShowSpinner ? "0.5" : "1",
          pointerEvents: isShowSpinner ? "none" : "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            padding: "0 1rem",
            marginBottom: "0.9375rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "1.875rem",
                  color: COLORS.black,
                  fontWeight: FONT_WEIGHT.bold,
                }}
              >
                <Text>MR Forecast</Text>
              </span>
              <span>
                <Button
                  id="display"
                  title="Display SQL"
                  type="submit"
                  variant="secondary"
                  style={{
                    marginLeft: "0.625rem",
                    textAlign: "center",
                    // width: "10%",
                    height: "45px",
                    width: "60px",
                    borderColor: COLORS.white,
                  }}
                  onClick={openPopup}
                />
                {isModalOpen && (
                  <DisplayModal
                    isOpen
                    setIsOpen={setIsModalOpen}
                    displayQuery={statusQuery}
                    setIsInit={setIsInit}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "1.3125rem 0 0.3125rem 0",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "0 1rem",
          }}
        >
          <span
            style={{
              fontSize: "0.875rem",
              color: COLORS.black1,
              fontWeight: FONT_WEIGHT.regular,
              width: "25%",
            }}
          >
            <Text>{`Refresh Time: ${refreshTime} HKT`}</Text>
          </span>
          <span
            style={{
              fontSize: "0.875rem",
              color: COLORS.black1,
              fontWeight: FONT_WEIGHT.regular,
              width: "25%",
            }}
          >
            <Text>
              Current Time:
              <DateTime />
            </Text>
          </span>
          <span
            style={{
              fontWeight: FONT_WEIGHT.regular,
              color: COLORS.black1,
              fontSize: "0.875rem",
              width: "20%",
            }}
          >
            <CheckBox2
              defaultChecked={alertColor}
              name="showHighLight"
              label="Show Highlight"
              onChange={showColor}
            />
          </span>
          <span
            style={{
              fontWeight: FONT_WEIGHT.regular,
              color: COLORS.black1,
              fontSize: "0.875rem",
              width: "20%",
            }}
          >
            <CheckBox2
              defaultChecked={highLight}
              name="highLight"
              label="Show Highlight Only"
              onChange={handleChecked}
            />
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              id="refresh"
              title="Refresh"
              type="submit"
              variant="secondary"
              style={{
                position: "relative",
                float: "inline-start",
                marginLeft: "0.625rem",
                textAlign: "center",
                // width: "10%",
                height: "45px",
                width: "80px",
              }}
            />
          </form>
        </div>
        <div
          style={{
            padding: "0 1rem",
          }}
        >
          <Line
            commonStyles={{
              marginTop: "0.875rem",
              marginBottom: "0.8rem",
            }}
          />
        </div>

        <TableBox
          searchResults={searchResults || []}
          totalRecords={totalRecords}
          limit={limit}
          fetchRefreshResults={fetchRefreshResults}
          alertColor={alertColor}
        />
        <div
          style={{
            padding: "0 1rem",
          }}
        >
          <Line
            commonStyles={{
              marginTop: "0.875rem",
              marginBottom: "0.8rem",
            }}
          />
        </div>
        <div
          style={{
            padding: "0 1rem",
            marginBottom: "0.8rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "1.875rem",
                  color: COLORS.black,
                  fontWeight: FONT_WEIGHT.bold,
                }}
              >
                <Text>MR Forecast Pending Message</Text>
              </span>
              <span>
                <Button
                  id="display"
                  title="Display SQL"
                  type="submit"
                  variant="secondary"
                  style={{
                    marginLeft: "0.625rem",
                    textAlign: "center",
                    // width: "10%",
                    height: "45px",
                    width: "60px",
                    borderColor: COLORS.white,
                  }}
                  onClick={openPendingPopup}
                />
                {isPendingModalOpen && (
                  <DisplayModal
                    isOpen
                    setIsOpen={setIsPendingModalOpen}
                    displayQuery={pendingQuery}
                    setIsInit={setIsInit}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "1.3125rem 0 0.3125rem 0",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "0 1rem",
          }}
        >
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              color: COLORS.black1,
              fontSize: "1rem",
              paddingLeft: "0.1rem",
            }}
          >
            <Text>{`No. of items: ${pendingTotal}`}</Text>
          </span>
        </div>
        <MRForecastBox
          searchResults={pendingResults || []}
          totalRecords={pendingTotal}
          limit={limit}
          fetchRefreshResults={fetchRefreshResults}
        />
      </div>
    </>
  );
};

export default MRforecastPage;
